import React from 'react';

function App() {
  return (
    <div>
      <h1>Invlid URL</h1>
    </div>
  );
}

export default App;